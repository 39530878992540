import { consume } from '@lit/context';
import { Task } from '@lit/task';
import { HttpClient, clientContext } from 'context/client-context';
import { LitElement, css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { styles } from 'pli/styles';
import { SelectValueResponse } from 'rules/poc/types/rules-details-types';
import { urlParamsBuilder } from 'utils/url-params-builder';

@customElement('searchable-combobox')
class SearchableCombobox extends LitElement {
    static styles = [
        styles.base,
        styles.flex,
        css`
            input {
                appearance: none;
                padding: var(--size-0-5) var(--size-1);
                border-radius: var(--radius-md);
                background-color: var(--body-bg);
                border: var(--border-default);
                align-self: flex-start;
                width: 100%;
            }

            pli-input {
                display: inline-flex;
                width: auto;
            }
        `,
    ];
    @consume({ context: clientContext, subscribe: true })
    client: HttpClient;

    @property()
    value?: string = '';

    @property()
    url: string;

    @property()
    placeholder: string;

    @state()
    _items = [];

    private _task = new Task(this, {
        task: async ([value]) => {
            const url = this.url;
            const query = urlParamsBuilder({ prefix: value });
            const result = await this.client.get(`${url}?${query}`);
            const json = (await result.json()) as SelectValueResponse;
            this._items = [...json.list];
            return json;
        },
        args: () => [this.value] as const,
    });

    emit(value: string) {
        this.dispatchEvent(
            new CustomEvent('update', {
                composed: true,
                detail: {
                    value,
                },
            }),
        );
    }

    handleValueChange = (event: Event) => {
        const value = (event.currentTarget as HTMLInputElement).value;
        this.emit(value);
    };

    render() {
        return html`
            <input
                list="items"
                id="items-choice"
                name="items-choice"
                value="${this.value}"
                placeholder="${this.placeholder}"
                @input="${this.handleValueChange}"
            />
            ${Boolean(this._items.length)
                ? html`
                      <datalist id="items" role="listbox">
                          ${this._items.map((item) => html`<option value="${item.name}">${item.name}</option>`)}
                      </datalist>
                  `
                : null}
        `;
    }
}
