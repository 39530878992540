import { LitElement, css, html } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { Ref, createRef, ref } from 'lit/directives/ref.js';
import { repeat } from 'lit/directives/repeat.js';
import '../../../../pli/pli-button';
import '../../../../pli/pli-icon';
import { Trigger, TriggerDisplayed } from '../../types/rules-details-types';
import { consume } from '@lit/context';
import { triggerContext } from '../../context/trigger-context';
import { ruleDetailsStyles } from 'rules/poc/styles';
import { styles } from 'pli/styles';

@customElement('rules-details-given')
class RulesDetailsGiven extends LitElement {
    static styles = [
        styles.base,
        styles.grid,
        styles.flex,
        ruleDetailsStyles.addToSelect,
        ruleDetailsStyles.ruleAdded,
        css`
            fieldset {
                appearance: none;
                border: 0;
            }

            .given {
                border-radius: var(--radius-md);
                padding: var(--size-1);
            }
            .given {
                background-color: var(--color-jordy-blue);
            }
            .given .select {
                color: var(--button-color-text);
            }
            .given label {
                border-bottom: 1px solid var(--color-border);
                padding-bottom: var(--size-0-5);
            }
            .given pli-button .flex {
                margin-right: auto;
            }

            @keyframes slideUp {
                from {
                    transform: var(--transform-start);
                    opacity: 0;
                }
                to {
                    transform: var(--transform-end);
                    opacity: 1;
                }
            }

            .condition {
                background-color: var(--color-white);
                padding-left: var(--size-1);
                border-radius: var(--radius-md);
            }
        `,
    ];

    @state()
    _triggers: Trigger[] = [];

    @consume({ context: triggerContext, subscribe: true })
    _savedTriggers: Trigger[];

    _options: TriggerDisplayed[] = [
        {
            name: 'Transaction is received',
            type: 'TransactionReceived',
            template: html`<span>Transaction is <strong>received</strong></span>`,
        },
        {
            name: 'Transaction is sent',
            type: 'TransactionSent',
            template: html`<span>Transaction is <strong>sent</strong></span>`,
        },
    ];

    _selectRef: Ref<HTMLSelectElement> = createRef();

    emit(triggers: Trigger[]) {
        const event: TriggersUpdatedEvent = new CustomEvent('update', {
            composed: true,
            detail: {
                value: triggers,
            },
        });
        this.dispatchEvent(event);
    }

    add = (event: Event) => {
        const added = this._options.find((option) => option.type === (event.currentTarget as HTMLSelectElement).value);
        this.addToTriggers(added);
        this.clearSelectElement();
        this.emit(this._triggers);
    };

    removeTrigger = (index: number) => {
        this._triggers = [...this._triggers.filter((_item, i) => index !== i)];
        this.emit(this._triggers);
        if (!this._triggers.length) {
            this.clearSelectElement();
            this.focusSelectElement();
        }
    };

    onKeyUpHandler = (event: KeyboardEvent, index) => {
        if (event.key === 'Backspace') this.removeTrigger(index);
        this.emit(this._triggers);

        if (!this._triggers.length) {
            this.focusSelectElement();
        }
    };

    private addToTriggers(added: TriggerDisplayed) {
        this._triggers = [...this._triggers, { type: added.type }];
    }

    private focusSelectElement() {
        this._selectRef.value.focus();
    }

    private clearSelectElement() {
        this._selectRef.value.value = '';
    }

    private setInitial() {
        this._triggers = [...this._savedTriggers];
    }

    render() {
        this.setInitial();
        const { onKeyUpHandler, _options: options } = this;

        const mapTriggersToDisplay = (triggers: Trigger[]): TriggerDisplayed[] => {
            return triggers.map((trigger) => ({
                ...this._options.find((t) => t.type === trigger.type),
            }));
        };

        return html`<div>
            <fieldset class="given grid-vertical gap-1">
                <label><strong>Given</strong></label>
                ${repeat(mapTriggersToDisplay(this._triggers), (trigger, index) => {
                    return html`
                        <div class="trigger" @keydown="${(event: KeyboardEvent) => onKeyUpHandler(event, index)}">
                            <div class="flex condition">
                                <div class="flex flex-1 items-center gap-1">
                                    <pli-icon name="arrow-left-right" slot="icon-left"></pli-icon>
                                    ${trigger.template}
                                </div>
                                <pli-button
                                    justify="space-between"
                                    variant="text"
                                    size="lg"
                                    .onClick="${() => this.removeTrigger(index)}"
                                >
                                    <pli-icon name="x" size="24" slot="icon-right"></pli-icon>
                                </pli-button>
                            </div>
                        </div>
                        <div class="flex justify-center"><pli-text>or</pli-text></div>
                    `;
                })}
                <div class="select">
                    <pli-icon size="24" name="plus-circle-fill"></pli-icon>
                    <select ${ref(this._selectRef)} class="select" @change="${this.add}">
                        <option value="" disabled selected>Add trigger</option>
                        ${options.map((option) => html` <option value="${option.type}">${option.name}</option> `)}
                    </select>
                </div>
            </fieldset>
        </div>`;
    }
}

export type TriggersUpdatedEvent = CustomEvent<{ value: Trigger[] }>;
