import { LitElement, css, html } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { Ref, createRef, ref } from 'lit/directives/ref.js';
import '../../../../pli/pli-button';
import '../../../../pli/pli-icon';
import { Condition, ConditionDisplayed, ConditionFieldset, OptGroupLabel } from '../../types/rules-details-types';
import { PliIconProps } from '../../../../pli/pli-icon';
import './render-controls';
import { styles } from 'pli/styles';
import { consume } from '@lit/context';
import { conditionContext } from '../../context/condition-context';
import { ruleDetailsStyles } from 'rules/poc/styles';

type ConditionOptGroup = { label: OptGroupLabel; options: ConditionDisplayed[] };

const definedOptions: ConditionOptGroup[] = [
    {
        label: 'Cumulative',
        options: [
            {
                name: 'Count...',
                type: 'CumulativeTransactionCount',
                template: html`<span><strong>Cumulative Transaction Count </strong>is</span>`,
            },
            {
                name: 'Sum...',
                type: 'CumulativeTransactionSum',
                template: html`<span><strong>Cumulative Transaction Sum </strong>is</span>`,
            },
        ],
    },
    {
        label: 'Time',
        options: [
            {
                name: 'Period...',
                type: 'Period',
                template: html`<span><strong>Time Period </strong>is within</span>`,
            },
        ],
    },
    {
        label: 'Customer',
        options: [
            {
                name: 'Attribute...',
                type: 'CustomPropertyCustomer',
                template: html`<span><strong>Customer </strong>Attribute</span>`,
            },
            {
                name: 'KYC Attribute...',
                type: 'CustomPropertyKyc',
                template: html`<span><strong>KYC </strong>Attribute</span>`,
            },
        ],
    },
    {
        label: 'Transaction',
        options: [
            {
                name: 'Amount...',
                type: 'TransactionAmount',
                template: html`<span><strong>Transaction Amount </strong>is</span>`,
            },
            {
                name: 'Currency...',
                type: 'TransactionCurrency',
                template: html`<span><strong>Transaction Currency </strong>is</span>`,
            },
            {
                name: 'Attribute...',
                type: 'CustomPropertyTransaction',
                template: html`<span><strong>Transaction </strong>Attribute</span>`,
            },
        ],
    },
    {
        label: 'Transaction Party',
        options: [
            {
                name: 'Attribute...',
                type: 'CustomPropertyTransactionParty',
                template: html`<span><strong>Transaction Party </strong>attribute</span>`,
            },
        ],
    },
];
@customElement('rules-details-when')
class RulesDetailsWhen extends LitElement {
    static styles = [
        styles.base,
        styles.grid,
        styles.flex,
        styles.padding,
        ruleDetailsStyles.addToSelect,
        ruleDetailsStyles.ruleAdded,
        css`
            fieldset {
                appearance: none;
                border: 0;
            }

            .when {
                border-radius: var(--radius-md);
                padding: var(--size-1);
            }
            .when {
                background-color: var(--color-peach);
            }
            .when .select {
                color: var(--color-browange);
            }

            @keyframes slideUp {
                from {
                    transform: var(--transform-start);
                    opacity: 0;
                }
                to {
                    transform: var(--transform-end);
                    opacity: 1;
                }
            }

            .condition {
                background-color: var(--color-white);
                padding: var(--size-1);
                padding-right: 0;
                border-radius: var(--radius-md);
            }

            .remove-button-wrapper {
                width: var(--size-3-5);
                flex-shrink: 0;
                margin-left: auto;
            }

            .condition-disabled {
                pointer-events: none;
                opacity: 0.5;
            }
        `,
    ];

    @state()
    _conditions: Condition[] = [];

    @consume({ context: conditionContext, subscribe: true })
    _savedConditions: Condition[];

    optGroups: { label: OptGroupLabel; options: ConditionDisplayed[] }[] = definedOptions;

    getOptionType = (type: Condition['type']) => {
        const optionsFlattened = this.optGroups.map((o) => o.options).flat();
        const match = optionsFlattened.find((o) => o.type === type);
        return match;
    };

    emit(conditions: Condition[]) {
        const event: ConditionsUpdatedEvent = new CustomEvent('update', {
            composed: true,
            detail: {
                value: conditions,
            },
        });
        this.dispatchEvent(event);
    }

    selectRef: Ref<HTMLSelectElement> = createRef();
    listRef: Ref<HTMLDivElement> = createRef();

    add = (event: Event) => {
        const value = (event.currentTarget as HTMLSelectElement).value as Condition['type'];
        this.clearSelectElement();
        const condition = this.getOptionType(value);
        this._setConditions([condition, ...this._conditions]);
        this.emit(this._conditions);
    };

    removeCondition = (index: number) => {
        this._setConditions([...this._conditions.filter((_item, i) => index !== i)]);
        this.emit(this._conditions);
        if (!this._conditions.length) {
            this.focusSelectElement();
        }
    };

    private focusSelectElement() {
        this.selectRef.value.focus();
    }

    private async clearSelectElement() {
        this.requestUpdate();
        await this.updateComplete;
        this.selectRef.value.value = '';
    }

    _setConditions = (payload: Condition[]) => (this._conditions = payload);

    setInitialValues() {
        this._setConditions([...this._savedConditions]);
    }

    handleConditionChange = (event: CustomEvent, indexToUpdate: number) => {
        const updatedConditions = this._conditions.map((condition, index) =>
            index === indexToUpdate
                ? {
                      ...condition,
                      ...event.detail.value,
                  }
                : condition,
        );
        this._setConditions([...updatedConditions]);
        this.emit(this._conditions);
    };

    render() {
        this.setInitialValues();

        const { add, selectRef, listRef, optGroups, removeCondition, getOptionType, handleConditionChange } = this;

        const iconMap: Record<OptGroupLabel, PliIconProps['name'] | null> = {
            Cumulative: 'cash-coin',
            Time: 'calendar-date',
            Customer: null,
            Transaction: 'cash-coin',
            'Transaction Party': null,
        };

        const getIcon = (type: Condition['type']): PliIconProps['name'] => {
            const label = optGroups.find((group) => group.options.find((option) => option.type === type)).label;
            return iconMap[label];
        };

        const conditions: ConditionFieldset[] = this._conditions.map((condition) => ({
            ...condition,
            ...getOptionType(condition.type),
            icon: getIcon(condition.type),
        }));

        return html`
            <fieldset class="when grid-vertical gap-1">
                <label><strong>When</strong></label>
                <div class="select">
                    <pli-icon size="24" name="plus-circle-fill"></pli-icon>
                    <select ${ref(selectRef)} class="select" @input="${add}">
                        <option value="" disabled selected>Add Condition</option>
                        ${optGroups.map(
                            (group) => html`
                                <optgroup label="${group.label}">
                                    ${group.options.map(
                                        (option) => html` <option value="${option.type}">${option.name}</option>`,
                                    )}
                                </optgroup>
                            `,
                        )}
                    </select>
                </div>
                <div ${ref(listRef)}>
                    ${conditions.map(
                        (condition, index) =>
                            html`${index >= 1
                                    ? html`<div class="logical-divider py-1 flex justify-center">
                                          <pli-text>and</pli-text>
                                      </div>`
                                    : null}
                                <div class="trigger">
                                    <div class="condition flex items-center justify-between">
                                        <div class="flex wrap gap-1">
                                            <div class="flex items-center gap-1">
                                                ${condition.icon
                                                    ? html`<pli-icon size="24" name="${condition.icon}"></pli-icon>`
                                                    : null}
                                                ${condition.template}
                                            </div>
                                            <render-controls
                                                .index="${index}"
                                                @change="${(event: CustomEvent) => handleConditionChange(event, index)}"
                                                .condition="${condition}"
                                            ></render-controls>
                                        </div>
                                        <div class="remove-button-wrapper">
                                            <pli-button
                                                variant="text"
                                                size="lg"
                                                shape="square"
                                                .onClick="${() => removeCondition(index)}"
                                            >
                                                <pli-icon name="x" size="24"></pli-icon>
                                            </pli-button>
                                        </div>
                                    </div>
                                </div> `,
                    )}
                </div>
            </fieldset>
        `;
    }
}
export type ConditionsUpdatedEvent = CustomEvent<{ value: Condition[] }>;
