import { bindable, bindingMode } from 'aurelia-framework';

const statuses = {
    Live: {
        color: 'live',
        text: 'Live',
    },
    Editing: {
        color: 'editing',
        text: 'Editing',
    },
    Preview: {
        color: 'preview',
        text: 'Preview',
    },
    Paused: {
        color: 'paused',
        text: 'Paused',
    },
};

export class RuleStatus {
    @bindable status: string;

    color: string;
    text: string;

    statusChanged(value: string) {
        if (value === undefined || value === null) {
            return;
        }

        let status = statuses[value];

        if (status) {
            this.color = status.color;
            this.text = status.text;
        }
    }
}
