import { LitElement, css, TemplateResult, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { Condition, ConditionFieldset } from '../../types/rules-details-types';
import '../controls/select-operator';
import '../controls/select-value-type';
import '../controls/select-time-period';
import '../controls/select-currency';
import { styles } from 'pli/styles';
import '../controls/set-property';
import '../controls/searchable-combobox';

const controls = [
    'selectOperator',
    'selectOperatorExtended',
    'selectValueType',
    'selectTimePeriod',
    'setValue',
    'selectCustomerProperty',
    'selectKycProperty',
    'selectCurrency',
    'selectTransactionProperty',
    'selectTransactionPartyProperty',
    'setProperty',
] as const;
type ControlType = (typeof controls)[number];

@customElement('render-controls')
class RenderControls extends LitElement {
    static styles = [
        styles.base,
        styles.grid,
        styles.flex,
        css`
            .render-controls > * {
                display: inline-flex;
                width: auto;
            }
        `,
    ];
    @property({ attribute: false })
    condition: ConditionFieldset;

    @property({ type: Number })
    index: number;

    onChange = (event: CustomEvent, value: { [key: string]: unknown }) => {
        event.stopPropagation();

        this.dispatchEvent(
            new CustomEvent('change', {
                composed: true,
                detail: {
                    value,
                },
            }),
        );
    };

    _renderSelectValueType = (condition: Condition) =>
        html`<select-value-type
            @valueTypeUpdate="${(event: CustomEvent) =>
                this.onChange(event, { valueType: event.detail.valueType, value: event.detail.value })}"
            value="${condition.value}"
            valueType="${condition.valueType}"
        ></select-value-type>`;

    _renderSelectOperator = (condition: Condition) =>
        html`<select-operator
            @change="${(event: CustomEvent) => this.onChange(event, { operator: event.detail.value })}"
            value="${condition.operator}"
        ></select-operator>`;

    _renderSelectOperatorExtended = (condition: Condition) =>
        html`<select-operator
            @change="${(event: CustomEvent) => this.onChange(event, { operator: event.detail.value })}"
            value="${condition.operator}"
            variant="extended"
        ></select-operator>`;

    _renderSelectTimePeriod = (condition: Condition) =>
        html`<select-time-period
            @timePeriodUpdate="${(event: CustomEvent) =>
                this.onChange(event, { operator: event.detail.operator, value: event.detail.value })}"
            operator="${condition.operator}"
            value="${condition.value}"
        ></select-time-period>`;

    _renderSetValue = (condition: Condition) =>
        html`<pli-input
            @change="${(event: CustomEvent) => this.onChange(event, { value: event.detail.value })}"
            size="sm"
            placeholder="Set value"
            value="${condition.value}"
        ></pli-input>`;

    _renderSelectCustomerProperty = (condition: Condition) =>
        html`<searchable-combobox
            @update="${(event: CustomEvent) => this.onChange(event, { property: event.detail.value })}"
            value="${condition.property}"
            url="customer/properties"
            placeholder="Select property"
        ></searchable-combobox>`;

    _renderSelectKycProperty = (condition: Condition) =>
        html`<searchable-combobox
            @update="${(event: CustomEvent) => this.onChange(event, { property: event.detail.value })}"
            value="${condition.property}"
            url="customer/kyc/properties"
            placeholder="Select KYC property"
        ></searchable-combobox>`;

    _renderSelectCurrency = (condition: Condition) =>
        html`<select-currency
            @currencyUpdate="${(event: CustomEvent) => this.onChange(event, { value: event.detail.value })}"
            value="${condition.value}"
        ></select-currency>`;

    _renderSelectTransactionProperty = (condition: Condition) =>
        html`<searchable-combobox
            @update="${(event: CustomEvent) => this.onChange(event, { property: event.detail.value })}"
            value="${condition.property}"
            url="transactions/properties"
            placeholder="Select Transaction property"
        ></searchable-combobox>`;

    _renderSelectTransactionPartyProperty = (condition: Condition) =>
        html`<searchable-combobox
            @update="${(event: CustomEvent) => this.onChange(event, { property: event.detail.value })}"
            value="${condition.property}"
            url="transactions/party/properties"
            placeholder="Select Transaction Party property"
        ></searchable-combobox>`;

    _renderSetProperty = (condition: Condition) =>
        html`<set-property
            value="${condition.property}"
            @change="${(event: CustomEvent) => this.onChange(event, { property: event.detail.value })}"
        ></set-property>`;

    render() {
        const {
            condition,
            _renderSelectValueType,
            _renderSelectOperator,
            _renderSelectOperatorExtended,
            _renderSelectTimePeriod,
            _renderSetValue,
            _renderSelectCustomerProperty,
            _renderSelectKycProperty,
            _renderSelectCurrency,
            _renderSelectTransactionProperty,
            _renderSelectTransactionPartyProperty,
            _renderSetProperty,
        } = this;

        const controlsMap: Record<ConditionFieldset['type'], ControlType[]> = {
            CumulativeTransactionCount: ['selectOperator', 'selectValueType'],
            CumulativeTransactionSum: ['selectOperator', 'selectValueType'],
            Period: ['selectTimePeriod'],
            CustomPropertyCustomer: ['selectCustomerProperty', 'selectOperatorExtended', 'setValue'],
            CustomPropertyKyc: ['selectKycProperty', 'selectOperatorExtended', 'selectValueType'],
            TransactionAmount: ['selectOperator', 'selectValueType'],
            TransactionCurrency: ['selectCurrency'],
            CustomPropertyTransaction: ['selectTransactionProperty', 'selectOperatorExtended', 'selectValueType'],
            CustomPropertyTransactionParty: ['selectTransactionPartyProperty', 'selectOperatorExtended', 'selectValueType'],
        };

        const componentMap: Record<ControlType, TemplateResult> = {
            selectOperator: _renderSelectOperator(condition),
            selectOperatorExtended: _renderSelectOperatorExtended(condition),
            selectValueType: _renderSelectValueType(condition),
            selectTimePeriod: _renderSelectTimePeriod(condition),
            setValue: _renderSetValue(condition),
            selectCustomerProperty: _renderSelectCustomerProperty(condition),
            selectKycProperty: _renderSelectKycProperty(condition),
            selectCurrency: _renderSelectCurrency(condition),
            selectTransactionProperty: _renderSelectTransactionProperty(condition),
            selectTransactionPartyProperty: _renderSelectTransactionPartyProperty(condition),
            setProperty: _renderSetProperty(condition),
        };

        const controls = controlsMap[condition.type];
        if (!controls) {
            return null;
        }

        return html`<div class="render-controls flex gap-1 items-start">
            ${controls.map((control) => {
                return Boolean(control) ? html`${componentMap[control]}` : null;
            })}
        </div>`;
    }
}
